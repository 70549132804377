import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-edit-anz-popup',
  templateUrl: './edit-anz-popup.component.html',
  styleUrls: ['./edit-anz-popup.component.scss'],
})
export class EditAnzPopupComponent implements OnChanges, OnInit {
  @Input() minAnz: number;
  @Input() maxAnz: number;
  isEinfachauswahl: boolean = false;
  isPflichtfeld: boolean = false;

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
    this.updateCheckboxes();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.minAnz || changes.maxAnz) {
      this.updateCheckboxes();
    }
  }

  dismiss(data?: any) {
    this.modalCtrl.dismiss(data);
  }

  save() {
    if (this.minAnz > this.maxAnz) {
      this.minAnz = this.maxAnz;
    }
    this.dismiss({ minAnz: this.minAnz, maxAnz: this.maxAnz });
  }

  onEinfachauswahlChange() {
    if (this.isEinfachauswahl) {
      if (this.isPflichtfeld) {
        this.minAnz = 1;
      } else {
        this.minAnz = 0;
      }
      this.maxAnz = 1;
    }
  }

  onPflichtfeldChange() {
    if (this.isPflichtfeld) {
      this.minAnz = 1;
    }
  }

  private updateCheckboxes() {
    if (this.minAnz > this.maxAnz) {
      this.maxAnz = this.minAnz;
    }
    this.isEinfachauswahl = (this.minAnz === 0 || this.minAnz === 1) && this.maxAnz === 1;
    this.isPflichtfeld = this.minAnz > 0;
  }
}