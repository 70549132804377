import { Component, OnInit } from '@angular/core';
import { FormService } from 'src/app/data/service/form.service';

@Component({
  selector: 'app-magirus-header',
  templateUrl: './magirus-header.component.html',
  styleUrls: ['./magirus-header.component.scss'],
})
export class MagirusHeaderComponent implements OnInit {

  forms: any;

  constructor(private formService: FormService) { }

  async ngOnInit() {
    this.forms = await this.formService.getMyCompaniesForms().toPromise();
  }

  navigateToConfigurations() {
    //if forms.length == 1 than navigate to /forms/filled-forms-overview/ + formid, else to /forms
    if (this.forms.length == 1) {
      window.location.href = '/forms/filled-forms-overview/' + this.forms[0].id;
    } else {
      window.location.href = '/forms';
    }
  }

}
