import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-magirus-error-drag-and-drop',
  templateUrl: './magirus-error-drag-and-drop.component.html',
  styleUrls: ['./magirus-error-drag-and-drop.component.scss'],
})
export class MagirusErrorDragAndDropComponent implements OnInit {
  @Input() errorText: string[];

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() { }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  undo() {
    this.modalCtrl.dismiss({ action: 'undo' });
  }
}